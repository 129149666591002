import {Box, FormControl, Input, Option, Select, Textarea} from '@mui/joy';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {Controller} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {fieldProps, requiredFieldProps} from './helper';
import {CityAutocomplete} from '../../../../../components/CityAutocomplete';
import {FormErrorText} from '../../../../../components/joy-ui/forms/FormErrorText';
import {FormFieldLabel} from '../../../../../components/joy-ui/forms/FormFieldLabel';
import {CARE_COMPANY_TAX_SYSTEMS} from '../../../../../v1/app/company/company.consts';
import {CareJobTypes, ContactSource, StartContractTypeCare} from '../../../utils/constants';
import {UserSelect} from '../../user-select/components/UserSelect';

export const AdditionalTopCareFields = ({control, isLoading, errors, isSubmitted}) => {
    const {t} = useTranslation('createUser');

    const jobTypes = Object.values(CareJobTypes);
    const taxSystemOptions = useMemo(() => {
        return Object.values(CARE_COMPANY_TAX_SYSTEMS).map(taxSystem => (
            <Option value={taxSystem} key={taxSystem}>
                {t(`taxSystems.${taxSystem}`, {ns: 'companies'})}
            </Option>
        ));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <UserSelect
                data-cy="coach"
                label={t('coach')}
                name="coach"
                hasError={!!errors.coach}
                helperText={errors.coach?.message}
                control={control}
                isDisabled={isLoading}
                tabIndex={5}
                isV3
            />

            <Controller
                control={control}
                name="jobType"
                render={({field}) => (
                    <FormControl error={!!errors.jobType && isSubmitted}>
                        <FormFieldLabel label={`${t('jobType')} *`} />
                        <Select
                            data-cy="jobType"
                            {...field}
                            color={errors.jobType && isSubmitted ? 'danger' : 'neutral'}
                            disabled={isLoading}
                            error={!!errors.jobType && isSubmitted}
                            placeholder={t('jobType')}
                            tabIndex={6}
                            onChange={(_, value) => {
                                field.onChange(value);
                            }}
                            {...requiredFieldProps}
                            sx={{
                                ...requiredFieldProps.sx,
                                '& button': {
                                    textTransform: 'capitalize',
                                },
                            }}
                        >
                            {jobTypes.map(jobType => {
                                return (
                                    <Option
                                        key={jobType}
                                        value={jobType}
                                        sx={{
                                            textTransform: 'capitalize',
                                        }}
                                    >
                                        {t(`careJobTypes.${jobType}`)}
                                    </Option>
                                );
                            })}
                        </Select>
                        <FormErrorText errorMessage={isSubmitted ? errors.jobType?.message : undefined} />
                    </FormControl>
                )}
            />

            <Box sx={{
                gridColumn: {
                    desktop: 'span 2',
                    mobile: '1',
                },
            }}
            >
                <Controller
                    control={control}
                    name="taxSystem"
                    render={({field}) => (
                        <FormControl error={!!errors.taxSystem && isSubmitted}>
                            <FormFieldLabel label={t('tax')} />
                            <Select
                                data-cy="taxSystem"
                                {...field}
                                color={errors.taxSystem && isSubmitted ? 'danger' : 'neutral'}
                                disabled={isLoading}
                                error={!!errors.taxSystem && isSubmitted}
                                placeholder={t('tax')}
                                tabIndex={7}
                                onChange={(_, value) => {
                                    field.onChange(value);
                                }}
                                {...requiredFieldProps}
                            >
                                {taxSystemOptions}
                            </Select>
                            <FormErrorText errorMessage={isSubmitted ? errors.taxSystem?.message : undefined} />
                        </FormControl>
                    )}
                />
            </Box>
        </>
    );
};

AdditionalTopCareFields.propTypes = {
    control: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    errors: PropTypes.object,
    isSubmitted: PropTypes.bool.isRequired,
};

AdditionalTopCareFields.defaultProps = {
    isLoading: false,
    errors: null,
};

export const AdditionalCareFields = function ({control, isLoading, startContractType, errors}) {
    const {t} = useTranslation('createUser');

    return (
        <Box sx={{
            display: 'grid',
            gridTemplateColumns: {
                desktop: 'repeat(2, 1fr)',
                mobile: 'none',
            },
            gridColumnGap: 24,
            gridRowGap: 24,
        }}
        >
            <Box sx={{
                gridColumn: {
                    desktop: 'span 2',
                    mobile: '1',
                },
            }}
            >
                <CityAutocomplete
                    data-cy="city"
                    label={t('city')}
                    name="city"
                    placeholder={t('city')}
                    control={control}
                    disabled={isLoading}
                    tabIndex={8}
                    error={!!errors.city}
                    helperText={errors.city?.message}
                    isV3
                />
            </Box>

            <Controller
                control={control}
                name="contactSource"
                render={({field}) => (
                    <FormControl error={!!errors.contactSource}>
                        <FormFieldLabel label={t('contactSource')} />
                        <Select
                            data-cy="contactSource"
                            {...field}
                            color={errors.contactSource ? 'danger' : 'neutral'}
                            disabled={isLoading}
                            error={!!errors.contactSource}
                            placeholder={t('contactSource')}
                            tabIndex={9}
                            onChange={(_, value) => {
                                field.onChange(value);
                            }}
                            {...fieldProps}
                        >
                            {Object.values(ContactSource).map(contactSource => {
                                return (
                                    <Option key={contactSource} value={contactSource}>
                                        {t(`contactSources.${contactSource}`)}
                                    </Option>
                                );
                            })}
                        </Select>
                        <FormErrorText errorMessage={errors.contactSource?.message} />
                    </FormControl>
                )}
            />

            <Controller
                control={control}
                name="startContractType"
                render={({field}) => (
                    <FormControl error={!!errors.startContractType}>
                        <FormFieldLabel label={t('startContractType')} />
                        <Select
                            data-cy="startContractType"
                            {...field}
                            color={errors.startContractType ? 'danger' : 'neutral'}
                            disabled={isLoading}
                            error={!!errors.startContractType}
                            placeholder={t('startContractType')}
                            tabIndex={10}
                            onChange={(_, value) => {
                                field.onChange(value);
                            }}
                            {...fieldProps}
                        >
                            {Object.values(StartContractTypeCare).map(startContractType => {
                                return (
                                    <Option key={startContractType} value={startContractType}>
                                        {t(`startContractTypes.${startContractType}`)}
                                    </Option>
                                );
                            })}
                        </Select>
                        <FormErrorText errorMessage={errors.startContractType?.message} />
                    </FormControl>
                )}
            />

            <Controller
                control={control}
                name="registrationDate"
                render={({field}) => (
                    <FormControl error={!!errors.registrationDate}>
                        <FormFieldLabel label={t('registrationDate')} />
                        <Input
                            data-cy="registrationDate"
                            type="date"
                            color={errors.registrationDate ? 'danger' : 'neutral'}
                            error={!!errors.registrationDate}
                            tabIndex={11}
                            disabled={startContractType === StartContractTypeCare.DIRECT_START_WITHOUT_PE
                                || isLoading}
                            {...field}
                            placeholder="dd/mm/yyyy"
                            {...fieldProps}
                        />
                        <FormErrorText errorMessage={errors.registrationDate?.message} />
                    </FormControl>
                )}
            />

            <Controller
                control={control}
                name="estimatedRegistrationDate"
                render={({field}) => (
                    <FormControl error={!!errors.estimatedRegistrationDate}>
                        <FormFieldLabel label={t('estimatedRegistrationDateCare')} />
                        <Input
                            data-cy="estimatedRegistrationDate"
                            type="date"
                            color={errors.estimatedRegistrationDate ? 'danger' : 'neutral'}
                            error={!!errors.estimatedRegistrationDate}
                            tabIndex={12}
                            disabled={isLoading}
                            {...field}
                            placeholder="dd/mm/yyyy"
                            {...fieldProps}
                        />
                        <FormErrorText errorMessage={errors.estimatedRegistrationDate?.message} />
                    </FormControl>
                )}
            />

            <Box sx={{
                gridColumn: {
                    desktop: 'span 2',
                    mobile: '1',
                },
            }}
            >
                <Controller
                    control={control}
                    name="note"
                    render={({field}) => (
                        <FormControl error={!!errors.note}>
                            <FormFieldLabel label={t('note')} />
                            <Textarea
                                type="text"
                                color={errors.note ? 'danger' : 'neutral'}
                                placeholder={t('note')}
                                error={!!errors.note}
                                multiline
                                minRows={3}
                                maxRows={6}
                                tabIndex={13}
                                disabled={isLoading}
                                {...field}
                                {...fieldProps}
                            />
                            <FormErrorText errorMessage={errors.note?.message} />
                        </FormControl>
                    )}
                />
            </Box>
        </Box>
    );
};

AdditionalCareFields.propTypes = {
    control: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    startContractType: PropTypes.string.isRequired,
    errors: PropTypes.object.isRequired,
};

import {Box, FormControl, Input, Option, Select, Textarea} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';
import {Controller} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {fieldProps} from './helper';
import {CityAutocomplete} from '../../../../../components/CityAutocomplete';
import {FormErrorText} from '../../../../../components/joy-ui/forms/FormErrorText';
import {FormFieldLabel} from '../../../../../components/joy-ui/forms/FormFieldLabel';
import {ProfessionAndSpecializationSelectors} from '../../../../job-profession-and-specilties/store/profession-and-specialization.selector';
import {LoadingSelectors, LoadingTypes} from '../../../../loading';
import {ContactSource, StartContractType} from '../../../utils/constants';

export const AdditionalFreelancerFields = function ({errors, control, startContractType, isLoading}) {
    const {t} = useTranslation('createUser');

    const jobTypes = useSelector(ProfessionAndSpecializationSelectors.getAllJobProfessions());
    const isLoadingJobTypes = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.GET_PROFESSION_VALUE),
    );

    return (
        <Box sx={{
            display: 'grid',
            gridTemplateColumns: {
                desktop: 'repeat(2, 1fr)',
                mobile: 'none',
            },
            gridColumnGap: 24,
            gridRowGap: 24,
        }}
        >
            <Box sx={{
                gridColumn: {
                    desktop: 'span 2',
                    mobile: '1',
                },
            }}
            >
                <Controller
                    control={control}
                    name="jobType"
                    render={({field}) => (
                        <FormControl error={!!errors.jobType}>
                            <FormFieldLabel label={t('jobType')} />
                            <Select
                                data-cy="jobType"
                                {...field}
                                color={errors.jobType ? 'danger' : 'neutral'}
                                disabled={isLoading || isLoadingJobTypes}
                                error={!!errors.jobType}
                                placeholder={t('jobType')}
                                tabIndex={6}
                                onChange={(_, value) => {
                                    field.onChange(value);
                                }}
                                {...fieldProps}
                            >
                                {jobTypes.map(jobType => {
                                    return (
                                        <Option key={jobType} value={jobType}>
                                            {t(`jobTypes.${jobType}`)}
                                        </Option>
                                    );
                                })}
                            </Select>
                            <FormErrorText errorMessage={errors.jobType?.message} />
                        </FormControl>
                    )}
                />
            </Box>

            <CityAutocomplete
                data-cy="city"
                label={t('city')}
                name="city"
                placeholder={t('city')}
                control={control}
                disabled={isLoading}
                error={!!errors.city}
                helperText={errors.city?.message}
                tabIndex={7}
                isV3
            />

            <Controller
                control={control}
                name="contactSource"
                render={({field}) => (
                    <FormControl error={!!errors.contactSource}>
                        <FormFieldLabel label={t('contactSource')} />
                        <Select
                            data-cy="contactSource"
                            {...field}
                            color={errors.contactSource ? 'danger' : 'neutral'}
                            disabled={isLoading}
                            error={!!errors.contactSource}
                            placeholder={t('contactSource')}
                            tabIndex={8}
                            onChange={(_, value) => {
                                field.onChange(value);
                            }}
                            {...fieldProps}
                        >
                            {Object.values(ContactSource).map(contactSource => {
                                return (
                                    <Option key={contactSource} value={contactSource}>
                                        {t(`contactSources.${contactSource}`)}
                                    </Option>
                                );
                            })}
                        </Select>
                        <FormErrorText errorMessage={errors.contactSource?.message} />
                    </FormControl>
                )}
            />

            <Controller
                control={control}
                name="startContractType"
                render={({field}) => (
                    <FormControl error={!!errors.startContractType}>
                        <FormFieldLabel label={t('startContractType')} />
                        <Select
                            data-cy="startContractType"
                            {...field}
                            color={errors.startContractType ? 'danger' : 'neutral'}
                            disabled={isLoading}
                            error={!!errors.startContractType}
                            placeholder={t('startContractType')}
                            tabIndex={9}
                            onChange={(_, value) => {
                                field.onChange(value);
                            }}
                            {...fieldProps}
                        >
                            {Object.values(StartContractType).map(startContractType => {
                                return (
                                    <Option key={startContractType} value={startContractType}>
                                        {t(`startContractTypes.${startContractType}`)}
                                    </Option>
                                );
                            })}
                        </Select>
                        <FormErrorText errorMessage={errors.startContractType?.message} />
                    </FormControl>
                )}
            />

            <Controller
                control={control}
                name="lastDayOfWork"
                render={({field}) => (
                    <FormControl error={!!errors.lastDayOfWork}>
                        <FormFieldLabel label={t('lastDayOfWork')} />
                        <Input
                            data-cy="lastDayOfWork"
                            type="date"
                            color={errors.lastDayOfWork ? 'danger' : 'neutral'}
                            error={!!errors.lastDayOfWork}
                            tabIndex={10}
                            disabled={isLoading}
                            {...field}
                            placeholder="dd/mm/yyyy"
                            {...fieldProps}
                        />
                        <FormErrorText errorMessage={errors.lastDayOfWork?.message} />
                    </FormControl>
                )}
            />

            <Controller
                control={control}
                name="registrationDate"
                render={({field}) => (
                    <FormControl error={!!errors.registrationDate}>
                        <FormFieldLabel label={t('registrationDate')} />
                        <Input
                            data-cy="registrationDate"
                            type="date"
                            color={errors.registrationDate ? 'danger' : 'neutral'}
                            error={!!errors.registrationDate}
                            tabIndex={11}
                            disabled={startContractType === StartContractType.DIRECT_START_WITHOUT_PE
                                || isLoading}
                            {...field}
                            placeholder="dd/mm/yyyy"
                            {...fieldProps}
                        />
                        <FormErrorText errorMessage={errors.registrationDate?.message} />
                    </FormControl>
                )}
            />

            <Controller
                control={control}
                name="estimatedRegistrationDate"
                render={({field}) => (
                    <FormControl error={!!errors.estimatedRegistrationDate}>
                        <FormFieldLabel label={t('estimatedRegistrationDate')} />
                        <Input
                            data-cy="estimatedRegistrationDate"
                            type="date"
                            color={errors.estimatedRegistrationDate ? 'danger' : 'neutral'}
                            error={!!errors.estimatedRegistrationDate}
                            tabIndex={12}
                            disabled={isLoading}
                            {...field}
                            placeholder="dd/mm/yyyy"
                            {...fieldProps}
                        />
                        <FormErrorText errorMessage={errors.estimatedRegistrationDate?.message} />
                    </FormControl>
                )}
            />

            <Box sx={{
                gridColumn: {
                    desktop: 'span 2',
                    mobile: '1',
                },
            }}
            >
                <Controller
                    control={control}
                    name="note"
                    render={({field}) => (
                        <FormControl error={!!errors.note}>
                            <FormFieldLabel label={t('note')} />
                            <Textarea
                                type="text"
                                color={errors.note ? 'danger' : 'neutral'}
                                placeholder={t('note')}
                                error={!!errors.note}
                                multiline
                                minRows={3}
                                maxRows={6}
                                tabIndex={13}
                                disabled={isLoading}
                                {...field}
                                {...fieldProps}
                            />
                            <FormErrorText errorMessage={errors.note?.message} />
                        </FormControl>
                    )}
                />
            </Box>
        </Box>
    );
};

AdditionalFreelancerFields.propTypes = {
    control: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    startContractType: PropTypes.string.isRequired,
};

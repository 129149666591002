import {RoutePaths} from '../../../lib/router/route-paths';
import {UNCATEGORIZED_FILTER_PARAM_NAME} from '../../bank/modules/account-balance/util/constants';

export const NavItems = {
    OVERVIEW: {name: 'overview'},
    REMUNERATION: {name: 'remuneration'},
    ACTIVITY: {name: 'activity'},
    INCOME_TAX: {name: 'incomeTax', isComingSoon: true},
    TREASURY: {name: 'treasury', isComingSoon: true},
    TAX_AND_SOCIAL_MANAGEMENT: {name: 'taxAndSocialManagement', isComingSoon: true},
    BILLING: {name: 'billing', isComingSoon: true},
};

export const TreasuryIndicatorStates = {
    OK: 'OK',
    INTERMEDIARY: 'INTERMEDIARY',
    ALERTING: 'ALERTING',
    NO_CONNECTED_BANK: 'NO_CONNECTED_BANK',
    ACCOUNT_BALANCE_ZERO: 'ACCOUNT_BALANCE_ZERO',
};

export const InvoicingIndicatorStates = {
    OK: 'OK',
    INTERMEDIARY: 'INTERMEDIARY',
    ALERTING: 'ALERTING',
    NO_INVOICES_YET: 'NO_INVOICES_YET',
};
export const CategorizationIndicatorStates = {
    OK: 'OK',
    INTERMEDIARY: 'INTERMEDIARY',
    ALERTING: 'ALERTING',
    NO_CONNECTED_BANK: 'NO_CONNECTED_BANK',
    NO_TRANSACTIONS_YET: 'NO_TRANSACTIONS_YET',
};

export const chartSettings = {
    lineColor: 'rgba(99, 107, 115, 0.15)',
    chartHeight: 360,
    barRadius: [6, 6, 0, 0],
    barWidth: 12,
    barWidthMobile: 6,
};

export const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const activityPeriod = {
    YEAR: 'YEAR',
    MONTH: 'MONTH',
};

export const TABLE_MAX_ROWS = {
    'MOBILE': 3,
    'DESKTOP': 6,
};

export const UNCATEGORIZED_TRANSACTIONS_PATH = `${RoutePaths.BANK_TRANSACTION_LIST}?${UNCATEGORIZED_FILTER_PARAM_NAME}`;

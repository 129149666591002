export const DEPOSIT_OF_ACCOUNTS_STATUS = {
    NOT_STARTED: 'NOT_STARTED',
    AWAITING_SIGNATURE: 'AWAITING_SIGNATURE',
    AWAITING_PAYMENT: 'AWAITING_PAYMENT',
    READY_TO_SEND: 'READY_TO_SEND',
    SENT_TO_INPI: 'SENT_TO_INPI',
    REJECTED_BY_INPI: 'REJECTED_BY_INPI',
    VALIDATED_BY_INPI: 'VALIDATED_BY_INPI',
    ACTION_REQUIRED_ON_INPI: 'ACTION_REQUIRED_ON_INPI',
    MISSING: 'MISSING',
    NOT_IN_USE: 'NOT_IN_USE',
};

export const DEPOSIT_OF_ACCOUNTS_DOCUMENT_STATUS = {
    MISSING: 'MISSING',
    NOT_IN_USE: 'NOT_IN_USE',
};

export const FORMALITIES_TABS = {
    ACCOUNTS: 'ACCOUNTS',
    VAT_DECLARATION: 'VAT_DECLARATION',
    OFFICE_CHANGE: 'OFFICE_CHANGE',
};

export const FORMALITIES_PAYMENT_STATUS = {
    VALID: 'VALID', // Pending
    EXPIRED: 'EXPIRED', // Final
    REVOKED: 'REVOKED', // Final
    COMPLETED: 'COMPLETED', // Final
};

export const DEPOSIT_OF_ACCOUNTS_PAY_MODAL_DELAY = 5000;

export const FORMALITY_SECTION_STATUS = {
    REQUIRED: 'REQUIRED',
    WAITING_FOR_REPORT_UPLOAD: 'WAITING_FOR_REPORT_UPLOAD',
    WAITING_FOR_FINAL_FEC_UPLOAD: 'WAITING_FOR_FINAL_FEC_UPLOAD',
    WAITING_FOR_SIGNATURE: 'WAITING_FOR_SIGNATURE',
    WAITING_FOR_PAYMENT: 'WAITING_FOR_PAYMENT',
};

export const DEPOSIT_OF_ACCOUNTS_FLOW = {
    UPLOAD_REPORT: 0,
    UPLOAD_FEC: 1,
    SIGNING_DOCUMENTS: 2,
    FORMALITY_PAYMENT: 3,
    READY_TO_SEND: 4,
};

export const DEPOSIT_OF_ACCOUNTS_DOCUMENTS = {
    ANNUAL_ACCOUNT_PRIVACY_STATEMENT: 'ANNUAL_ACCOUNT_PRIVACY_STATEMENT',
    POWER_OF_ATTORNEY_SUBMISSION_OF_ACCOUNTS: 'POWER_OF_ATTORNEY_SUBMISSION_OF_ACCOUNTS',
    PV_AGO: 'PV_AGO',
    ANNUAL_ACCOUNT_BALANCE_SHEET: 'ANNUAL_ACCOUNT_BALANCE_SHEET',
};

import {createSelector} from 'reselect';

const selectFileLoader = state => state.accounting.fileLoader;
const selectJournalAccounts = state => state.accounting.journalAccounts;
const selectAnnualAccounts = state => state.accounting.annualAccounts;
const selectFECDocuments = state => state.accounting.fecDocuments;
const selectBalanceSheetDetails = state => state.accounting.balanceSheetDetails;
const selectDocumentGenerationProgres = state => state.accounting.documentGenerationProgress;
const selectAllDocumentsGenerationProgress = state => state.accounting.allDocumentsGenerationProgress;
const selectINPISubmission = state => state.accounting.inpiSubmissionDetails;

const createFileLoaderSelectorByEventId = eventId => createSelector(
    [selectFileLoader],
    data => (data.find(el => el?.eventId === eventId)),
);

export const AccountingSelector = {
    selectFileLoader,
    createFileLoaderSelectorByEventId,
    selectJournalAccounts,
    selectAnnualAccounts,
    selectBalanceSheetDetails,
    selectDocumentGenerationProgres,
    selectAllDocumentsGenerationProgress,
    selectINPISubmission,
    selectFECDocuments,
};

export const AccountingActionTypes = {
    GET_JOURNAL_ACCOUNTS: 'accounting/GET_JOURNAL_ACCOUNTS',
    STORE_JOURNAL_ACCOUNTS: 'accounting/STORE_JOURNAL_ACCOUNTS',
    UPDATE_JOURNAL_ACCOUNTS: 'accounting/UPDATE_JOURNAL_ACCOUNTS',
    GET_ANNUAL_ACCOUNTS: 'accounting/GET_FINANCIAL_STATEMENTS',
    STORE_ANNUAL_ACCOUNTS: 'accounting/STORE_FINANCIAL_STATEMENTS',
    UPDATE_ANNUAL_ACCOUNT: 'accounting/UPDATE_ANNUAL_ACCOUNT',

    // FEC
    DOWNLOAD_FEC_FILE: 'accounting/DOWNLOAD_FEC_FILE',
    DOWNLOAD_FEC_FILE_LOADER: 'accounting/DOWNLOAD_FEC_FILE_LOADER',
    DOWNLOAD_FINAL_FEC_FILE: 'accounting/DOWNLOAD_FINAL_FEC_FILE',
    UPLOAD_FINAL_FEC_FILE: 'accounting/UPLOAD_FINAL_FEC_FILE',
    UPLOAD_FINAL_FEC_FILE_LOADER: 'accounting/UPLOAD_FINAL_FEC_FILE_LOADER',
    DELETE_FINAL_FEC_FILE: 'accounting/DELETE_FINAL_FEC_FILE',
    STORE_FEC_DOCUMENTS: 'accounting/STORE_FEC_DOCUMENTS',
    GET_FEC_DOCUMENTS: 'accounting/GET_FEC_DOCUMENTS',

    // Balance sheet
    STORE_BALANCE_SHEET_DETAILS: 'accounting/STORE_LATEST_BALANCE_SHEET',
    GET_BALANCE_SHEET_DETAILS: 'accounting/GET_BALANCE_SHEET_DETAILS',
    UPLOAD_BALANCE_SHEET: 'accounting/UPLOAD_BALANCE_SHEET',

    // Deposit of accounts
    TOGGLE_DEPOSIT_OF_ACCOUNTS_MODE: 'accounting/TOGGLE_FINANCIAL_STATEMENTS_MODE',
    GENERATE_DOA_DOCUMENTS: 'accounting/GENERATE_DOA_DOCUMENTS',
    REGENERATE_ALL_DOCUMENTS: 'accounting/REGENERATE_ALL_DOCUMENTS',
    REGENERATE_SINGLE_DOCUMENT: 'accounting/REGENERATE_SINGLE_DOCUMENT',
    STORE_DOCUMENT_GENERATING_PROGRESS: 'accounting/STORE_DOCUMENT_GENERATING_PROGRESS',
    STORE_ALL_DOCUMENTS_GENERATING_PROGRESS: 'accounting/STORE_ALL_DOCUMENTS_GENERATING_PROGRESS',
    SEND_FORMALITIES_TO_INPI: 'accounting/SEND_FORMALITIES_TO_INPI',
    STORE_INPI_SUBMISSION_DETAILS: 'accounting/STORE_INPI_SUBMISSION_DETAILS',
};
